@import "src/ui-kit/styles/vars";

.data-slider-container {
    &__content {
        width: 400px;
        background: $dark-gray;
        border-radius: 24px;
        padding-top: 24px !important;

        @media (max-width: $mobile-width) {
            width: 100%;
        }
    }

    &__title {
        padding-left: 24px;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: white;
        padding-bottom: 24px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px 24px 24px;

        &__info {
            display: flex;
            align-items: center;

            &__icon {
                padding-right: 12px;
                svg {
                    height: 48px;
                    width: 48px;
                }
            }

            &__title {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                padding-bottom: 4px;
            }

            &__value {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}