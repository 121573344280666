.info-line {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    font-weight: 700;

    &-m {
        font-size: 16px;
        line-height: 21px;
    }

    &-s {
        font-size: 12px;
        line-height: 16px;
    }

    &:last-child {
        padding-bottom: 0;
    }
}