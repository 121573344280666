@import "src/ui-kit/styles/vars";

.boost-modal {
  width: 420px;
  border-radius: 8px;

  background: $dark-gray;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__close {
    width: 14px;
    height: 14px;
    cursor: pointer;

    fill: white;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    border-bottom: 1px solid rgba(255,255,255,0.08);
    font-size: 18px;
  }

  &__content {
    padding: 20px 16px;
    text-align: center;

    &__button {
      width: 120px;
      height: 26px;
      font-size: 16px;
      line-height: 26px;
      border-radius: 4px;
      color: black;
      cursor: pointer;
    }
  }
}

.boost-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0,0,0, 0.7);
}