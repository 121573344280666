@import "src/ui-kit/styles/vars";

.deposit-info {
  margin-right: 40px;

  @media (max-width: $mobile-width) {
    padding: 20px 10px;
    margin: 0;
  }

  &__deposit {
    background: $black;
    border-radius: 24px;
    padding: 24px;

    &__input {
      margin-bottom: 32px;
    }
  }

  &__lockup {
    padding: 8px 0 16px 0;

    .deposit-booster__title {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__button {
    &-container {
      width: calc(100% - 16px);
      margin: 16px auto 0 auto;
    }
  }
}