@import "src/ui-kit/styles/vars";

.earn {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;

    &__section {
        &__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            padding-bottom: 24px;
        }

        &__content {
            margin-bottom: 24px;
        }
    }

    @media only screen and (max-width: $mobile-width) {
        max-width: none;
        padding: 0 6px;
    }
}
