@import "src/ui-kit/styles/vars";

.boost-modal-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  &__name {
    text-align: left;
    width: 40%;
    display: flex;
    align-items: center;

    &__text {
      padding: 0 6px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &__etherscan {
      cursor: pointer;
      width: 14px !important;
      height: 14px !important;
    }
  }

  &__apr {
    width: 30%;
  }

  &__button {
    width: 30%;
    margin-left: 20px;
    background: $black;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .button-gray--disabled {
    background: $dark;
  }
}