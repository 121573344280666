@import "src/ui-kit/styles/vars";

.earn-settings {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
    max-width: 850px;
    margin: 0 auto;

    &__title {
        padding-left: 24px;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: white;
        padding-bottom: 24px;
    }

    &__back {
        top: -60px;
    }

    &__gradient-border {
        width: auto;
    }

    @media (max-width: $mobile-width) {
        flex-direction: column;

        &__side-container {
            width: 100%;
            padding: 20px 10px;
        }
    }
}
