@import "src/ui-kit/styles/vars";

.rewards {
  color: white;
  margin-top: 40px;

  &__info {
    background: $black;
    border-radius: 24px;
    padding: 24px;
  }
}