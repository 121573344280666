@import "src/ui-kit/styles/vars";

.deposit-booster {
  padding: 0 16px 32px 16px;
  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;

    & > span {
      margin-right: 4px;
    }
  }

  &__values {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__value {
    border-radius: 40px;
    color: white;
    padding: 6px 12px;
    background: $dark-gray;
    margin-right: 12px;
    cursor: pointer;

    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    &:last-child {
      margin-right: 0;
    }

    &--selected {
      background: #FFFFFF !important;
      color: $black;
    }
  }
}