@import "src/ui-kit/styles/vars";

.borrow-info {
  position: relative;

  border: 1px solid #3D3D3D;
  box-sizing: border-box;
  border-radius: 12px;
  background: $black;
  padding: 16px;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  user-select: none;

  &__visible-data {
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    &__data {
      display: flex;
      align-items: center;
      &__apy {
        margin-right: 20px;
      }

      &__icon {
        transition: all 150ms linear;
      }
    }
  }

  &__hidden-data {
    height: 0;
    opacity: 0;
    transition: height 150ms linear;
    box-sizing: border-box;

    &__line {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;

      &--padded {
        padding-top: 8px;
      }

      &__title {
        display: flex;
        align-items: center;
      }

      &__content {
        display: flex;
        align-items: center;
      }

      &__icon {
        font-size: 16px;
        padding: 2px;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }

  &--visible {
    .borrow-info__visible-data {
      padding-bottom: 20px;

      &__data__icon {
        transform: rotate(180deg);
      }
    }

    .borrow-info__hidden-data {
      height: 134px;
      opacity: 1;
    }
  }
}