.disclaimer {
  font-family: 'Open Sans';

  position: relative;
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  margin-bottom: 44px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url("../../../../ui-kit/images/icons/disclaimer.svg");
  background-size: cover;
  border-radius: 12px;
  color: #0D0D0D;


  &__close {
    position: absolute;
    display: flex;
    height: 20px;
    width: 20px;
    top: 12px;
    right: 12px;
    cursor: pointer;
    padding: 4px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 0;
  }

  &__description {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}