@import "src/ui-kit/styles/vars";

.collateral-info {
  &__content {
    background: $black;
    border-radius: 24px;
    padding: 24px;

    &__input {
      margin-bottom: 32px;
    }
  }

  &__info {
    padding-bottom: 32px;
    border-bottom: 1px solid $dark-gray;
  }

  &__prices {
    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__price {
      max-width: 130px;

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $gray2;
        padding-bottom: 8px;
      }

      &__value {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        white-space: nowrap;
      }
    }
  }

  &__button {
    &-container {
      width: calc(100% - 16px);
      margin: 16px auto 0 auto;
    }
  }
}