@import "src/ui-kit/styles/vars";

.actions-modal {
  width: calc(100% - 16px);
  height: fit-content;
  max-width: 480px;
  padding: 24px 0;

  background: $dark-gray;
  border-radius: 16px;
  position: relative;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    padding-bottom: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      padding-left: 8px;
    }
  }

  &__close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__tabs {
    display: flex;
    width: 100%;
  }

  &__tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $gray2;
    cursor: pointer;

    padding-bottom: 12px;

    border-bottom: 1px solid #494949;

    &:hover {
      opacity: 0.8;
    }

    &__image {
      width: 14px;
      height: 14px;
    }

    &__name {
      padding-left: 4px;
    }

    &--selected {
      color: white;
      border-bottom: 1px solid white;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.actions-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(32, 32, 29, 0.8);
    backdrop-filter: blur(3px);
    z-index: 11;
}