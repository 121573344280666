.merged-icons {
  display: flex;

  svg:first-child {
    margin-right: -10px;
    z-index: 1;
  }

  &--m {
    svg {
      height: 28px;
      width: 28px;
    }
  }

  &--l {
    svg {
      height: 32px;
      width: 32px;
    }
  }
}