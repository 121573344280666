@import "src/ui-kit/styles/vars";

.empty-loans {
  margin: 0 auto;
  width: 400px;
  background: $black;
  border: 1px solid #3D3D3D;
  border-radius: 24px;
  padding: 32px 40px;

  &__title {
    padding-top: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }

  &__description {
    padding-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    &-container {
      width: 100%;
      margin: 32px auto 0 auto;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    border: none;
    width: 100%;
  }
}
