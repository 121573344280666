@import "src/ui-kit/styles/vars";

.assets-table {
  width: 640px;
  margin: 0 auto;
  text-align: left;

  &--no-deposits {
    .assets-table__body__apy {
      color: $gray1;
    }

    .assets-table__body__line {
      cursor: default;
    }
  }

  &__header {
    &__cell {
      user-select: none;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      cursor: pointer;
      text-transform: uppercase;
      padding: 0 16px 0 0;
      color: $gray1;

      svg {
        margin-left: 4px;
      }

      &:hover {
        color: $gray2;
      }
    }
  }

  &__body {
    &__line {
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      border-bottom: 1px solid #3D3D3D;

      td {
        padding: 16px 16px 16px 0;
      }

      &__icon {
        display: flex;
        justify-content: left;
        align-items: center;

        &__text {
          padding-left: 12px;
        }
      }

      &__button {
        display: flex;
        justify-content: right;
      }
    }

    &__rewards {
      text-align: right;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    width: 100%;

    &__header {
      &__cell {
        padding: 0 0 0 0;

        svg {
          margin-left: 2px;
        }
      }
    }

    &__body {
      &__line {
        cursor: pointer;

        td {
          width: 120px;
          padding: 12px 12px 12px 0;
        }

        &__icon {
          display: flex;
          justify-content: left;
          align-items: center;

          &__text {
            padding-left: 8px;
          }
        }
      }
    }
  }
}