@import "src/ui-kit/styles/vars";

.overview-chart {
  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px 30px 0;
    flex: 1;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    &__name {
      margin: 8px 8px 0 0;
      width: 215px;
    }
  }
}