@import "src/ui-kit/styles/vars";

.header-dropdown-container {
  display: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  &--visible {
    display: block;
  }
}

.header-dropdown {
  background: $dark-gray;
  border-radius: 8px;
  width: 160px;
  position: absolute;
  top: 68px;
  right: 35px;
  padding: 16px;

  &--inline {
    position: static;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 16px;
    cursor: pointer;

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      color: rgba(255,255,255, 0.8);
    }

    svg {
      width: 16px;
      height: 16px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding-left: 8px;
    }
  }

  a {
    color: white;

    &:hover {
      color: rgba(255,255,255, 0.8);
    }
  }

  @media only screen and (max-width: $mobile-width) {
    right: 55px;
    box-shadow: 0 0 0 1px $black;

    &--inline {
      box-shadow: none;
    }
  }
}