@import "src/ui-kit/styles/vars";

.loan-info {
  margin-left: 40px;

  &__content {
    background: $black;
    border-radius: 24px;
    padding: 24px;

    &__input {
      margin-bottom: 32px;

      &__available {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        margin-bottom: 13px;
      }
    }

    &__settings {
      padding: 24px 16px;
      border: 1px solid #3D3D3D;
      border-radius: 12px;

      &__slider {
        padding-bottom: 24px;
      }
    }
  }

  &__info {
    padding: 0 16px 24px 16px !important;
  }

  &__button {
    &-container {
      width: calc(100% - 16px);
      margin: 16px auto 0 auto;
    }
  }
}