@import "src/ui-kit/styles/vars";

.transactions-info {
  &__value {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    padding-top: 30px;
  }

  &__settings {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}