@import "src/ui-kit/styles/vars";

.borrow-input {
  display: flex;
  justify-content: space-between;
  padding: 32px 16px;

  &__token {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 67px;

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    &__icon svg {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }

    &__add {
      padding: 2px 6px 0 6px;
      cursor: pointer;
    }

    &__name {
      white-space: nowrap;
    }
  }

  &__input {
    background: transparent;
    outline: none;
    border: none;

    width: 215px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;

    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
  }
}