@import "src/ui-kit/styles/vars";

.analytics {
    width: 880px;
    margin: 0 auto;

    &__line {
        display: flex;
    }

    @media only screen and (max-width: $mobile-width) {
        width: 100%;
        padding: 0 8px;

        &__line {
            display: flex;
            flex-direction: column;
        }
    }
}
