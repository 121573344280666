@import "src/ui-kit/styles/vars";

.loans-table {
  width: 720px;
  margin: 0 auto;
  text-align: left;

  &__header {
    &__cell {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      cursor: pointer;
      text-transform: uppercase;
      padding: 16px;
      color: $gray1;

      svg {
        margin-left: 4px;
      }

      &:hover {
        color: $gray2;
      }
    }
  }

  &__body {
    box-shadow: 0 0 0 1px #3D3D3D;
    border-radius: 8px;

    &__line {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      border-bottom: 1px solid #3D3D3D;

      td {
        padding: 16px;
      }

      &__icon {
        display: flex;
        justify-content: left;
        align-items: center;

        div {
          padding-left: 8px;
        }
      }

      &__button {
        display: flex;
        justify-content: right;
      }

      &__ltv {
        display: flex;
        align-items: center;

        &__progress {
          width: 104px;
          padding: 0 24px 0 0;

          .rs-progress-line-bg {
            background: $gradient;
          }

          .rs-progress-line-inner {
            background: $dark-gray;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media only screen and (max-width: $mobile-width) {
    width: calc(100% - 16px);
    margin: 8px;

    &__header {
      &__cell {
        padding: 12px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &__body {
      &__line {

        &__ltv__progress {
          padding-right: 8px;
          width: 64px;
        }
        td {
          padding: 12px;
        }

        &__button {
          &-content {
            background: $dark-gray;
            height: 20px;
            width: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            line-height: 1;
            font-size: 30px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}