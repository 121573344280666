@import "src/ui-kit/styles/vars";

.internal-stats {
  width: 100vw;
  padding: 0 50px;

  &__section {
    padding-bottom: 40px;
  }

  &__header {
    font-size: 24px;
  }

  &__table {
    color: $black;
  }
}
