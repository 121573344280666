@import "src/ui-kit/styles/vars";

.earn-more {
  background: $black;
  padding: 24px;
  width: 400px;

  @media (max-width: $mobile-width) {
    width: 100%;
  }

  &__disclaimer {
    background: $gray3;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $black;

    &__text {
      text-align: center;
      max-width: 188px;

      span {
        color: #00a977;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 4px;
    }

    &__value {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
    }
  }
}