@import "src/ui-kit/styles/vars";

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 0 20px 0;
    color: $gray1;
    user-select: none;

    &__version {
        text-align: center;
        color: $gray1;
        font-size: 12px;
    }
}