@import "src/ui-kit/styles/vars";

.token-slider {
  display: flex;
  justify-content: space-between;

  input[type='range'] {
    flex: 1;
    margin-right: 12px;
    background: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      border-radius:10px;
      background: $gradient-fallback; /* fallback for old browsers */
      background: $gradient;
      height: 4px;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      box-sizing: border-box;
      padding: 0;
      height: 16px;
      width: 16px;
      border-radius: 16px;
      border: 2px solid #D8FFB0;
      margin-top: -6px;
      background: $black;
      cursor: grab;
      -moz-transition: border 250ms linear;
      transition: border 250ms linear;
    }

    &:focus {
      outline: none;
    }

    &::-ms-fill-lower {
      background: transparent;
    }
    &::-ms-thumb {
      box-sizing: border-box;
      padding: 0px;
      height: 20px;
      width: 10px;
      border-radius: 2px;
      border: 1px solid;
      background: #EEE;
    }
    &::-ms-track {
      box-sizing: border-box;
      width: 210px;
      height: 5px;
      border-radius: 2px;
      padding: 0px;
      background: #777;
      color: #777;
    }
    &::-moz-range-track {
      box-sizing: border-box;
      width: 200px;
      height: 5px;
      border-radius: 2px;
      padding: 0px;
      background: #777;
    }
    &::-moz-range-thumb {
      box-sizing: border-box;
      padding: 0px;
      height: 20px;
      width: 10px;
      border-radius: 2px;
      border: 1px solid;
      background: #EEE;
    }

    &.first::-webkit-slider-thumb {
      border-color: #DBB0FF;
    }
    &.second::-webkit-slider-thumb {
      border-color: #C2DCFF;
    }
    &.third::-webkit-slider-thumb {
      border-color: #B4FFE0;
    }
    &.fourth::-webkit-slider-thumb {
      border-color: #FEFBDA;
    }
    &.fifth::-webkit-slider-thumb {
      border-color: #FEE0EF;
    }
  }

  &__ltv {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    width: 62px;
  }
}