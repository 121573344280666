.loan-settings {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;

    max-width: 850px;
    margin: 0 auto;

    &__back {
        top: -60px;
    }
}
