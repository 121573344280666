@import "src/ui-kit/styles/vars";

.borrow {
    text-align: center;
    height: 100%;
    padding: 32px 16px 40px 16px;
    box-sizing: border-box;
    background: $black;
    width: 394px;
    border-radius: 24px;
    position: relative;

    &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: white;
        padding-bottom: 24px;
    }

    &__boost {
        position: absolute;
        right: 30px;
        top: 90px;
        background: $black;
    }

    .button-gray--disabled {
        background: $dark;
    }

    &__token-slider {
        padding: 0 16px 32px 16px;
    }

    &__button {
        &-container {
            width: calc(100% - 16px);
            margin: 40px auto 0 auto;
        }
    }

    @media only screen and (max-width: $mobile-width) {
        padding: 15px 8px;
        width: 100%;
    }
}
