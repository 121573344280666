@import "src/ui-kit/styles/vars";

.deposit-content {
  padding: 0 8px 0 8px;

  &__button-container {
    width: calc(100% - 16px);
    margin: 16px auto 0 auto;
  }

  &__lockup {
    padding: 6px 16px !important;

    .deposit-booster__value {
      background: $dark2;
      padding: 8px 18px;

      @media (max-width: $mobile-width) {
        padding: 8px 12px;
      }
    }
  }
}